import React from 'react'
import { connect } from 'react-redux'
import SpinnerLoader from '../../components/spinner/SpinnerLoader'
import BreadCrumb from '../../components/BreadCrumb';
import { addStudent } from '../../store/student/Actions';
import { fetchGradePost, fetchGradeStandardPost } from '../../store/classes/Actions';
import { studentInputTypes, StudentType } from '../../store/student/Types';
import { fetchParent } from '../../store/student/Actions';
import { Button, FormGroup, MenuItem, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { TextField,  RadioGroup as FormikRadioGroup } from 'formik-material-ui';
import { StudentValidation } from './StudentValidation';
import history from '../../History';
import ImageUpload  from './StudentImage';
import { fetchMyClassListGet, fetchMySectionListGet } from '../../store/classes/Actions';
import { getStudentClassesData } from '../../store/profile/Actions';
import { stopYearData } from '../../store/profile/Actions';
import { fetchLicenseBySchoolGet } from '../../store/license/Actions';
import toaster from "toasted-notes";
import { notificationMsg, maxDate } from '../../services/Constants';


export type OwnStudentFormProps = {
    getChangeYearData?:any;
	stopYearDate?:any;
    addStudent: (student: any) => any;
    fetchGradePost:(postValue:any) => void;
    fetchGradeStandardPost:() => any;
    fetchParent: (getParent:any) => any;
    fetchMyClassListGet:() => any;
    fetchMySectionListGet:(grade:any) => any;
    getStudentClassesData:(postData:any)=>any;
    stopYearData:()=>any;
    fetchLicenseBySchoolGet: (getData: any) => any;
    loading: boolean;    
    errorMessage: any;
    ListSection:any;
    ListClass:any;
    getParentResponse:any;
    checkParent:any;
    getImageURL:any;
    getProfile:any;
    license?: any;
    licenseBySchool?: any;
};
 const initialAddValues: StudentType = {
    phone_number: '',
    email_id: '',
    student_name:  '',
    academic_year:'',
    grade: '',
    standard:'',
    parent_firstname:  '',
    parent_lastname:  '',
    profile_picture: '',
    user_id:'sdad',
    gender:'',
    date_of_birth: '',
}

export class AddStudent extends React.Component<OwnStudentFormProps, studentInputTypes> {  
    formik:any;
    parentName:any;
    getSectionList:any;
    getImage:any;
    constructor(props: any) {        
        super(props);        
        this.state = {
            phone_number:'',
            email_id:'',
            student_name:'',
            academicYear:null,
            grade:'',
            standard:'',
            parent_firstname:'',
            parent_lastname:'',
            profile_picture:'',
            user_id:'',
            checkParentList:false,
            getSelectClass:true,
            getClass:'',
            getSection:'',
            date_of_birth: '',
        }  
    }
    componentDidMount(): void {     
        window.scrollTo(0, 0);  
        this.getCurrectYear()
    }
     // This is life cycle is initial load  component Will Update
     componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYearDate} = this.props;
		if(stopYearDate === true){
			this.getCurrectYear(this.props.getChangeYearData)
		}
	}

    // This is function is used get correct year
	getCurrectYear(getYear?:any){
		if(getYear && getYear !== undefined && getYear !== null){
			this.getAddStudent(getYear)
			this.setState({academicYear:getYear})
		}else {
			this.getAddStudent(this.props.getChangeYearData)
			this.setState({academicYear:this.props.getChangeYearData})
		}
	}
    getAddStudent(getYear?:any){
        this.getImage = '';
        const postValue = {
            academic_year:getYear
        }
        const getLicense = {
            module_name: 'student_module',
            academic_year: getYear
          }

          this.props.fetchLicenseBySchoolGet(getLicense).then((res:any) => {
            const { licenseBySchool } = this.props
            const studentLicense =  licenseBySchool;
            if(studentLicense.remaining_license && studentLicense.remaining_license <= 0){
                toaster.notify(studentLicense.message, {
                    position: 'top',
                    duration: notificationMsg.errorNotificationDuration
                  });

                history.push("/student");
            }
          })  
       
        this.props.fetchGradePost(postValue);
        this.props.fetchGradeStandardPost();  
        this.props.fetchMyClassListGet();  
        this.getSectionList = true;  
    }
    findParantDetail= (e: any) => {
        const { getProfile } = this.props;
        const { value } = e.target;
        if(value.length === 10) {
            const getPhone = {
                phone_number:value,
                school_id:getProfile.school_id
            };
            this.props.fetchParent(getPhone).then((res:any) => {
                if(this.props.checkParent === false){
                    this.formik.setFieldValue('email_id', '')  
                    this.formik.setFieldValue('parent_firstname', '')
                    this.formik.setFieldValue('parent_lastname', '')
                }
            });          
        }        
    }
   goBack(){
    history.push("/student");
   }
    componentDidUpdate(prevProps:any, prevState:any, snapshot:any) {   
        const { stopYearDate } = this.props;
        if(stopYearDate === true){
			this.props.stopYearData()
		}      
         // This is the function used to update old parent details
        this.parentName = false;
        if(this.props.getParentResponse.length === undefined){ 
            this.parentName = false;
            this.formik.setFieldValue('email_id', this.props.getParentResponse.email_id)  
            this.formik.setFieldValue('parent_firstname', this.props.getParentResponse.parent_firstname)
            this.formik.setFieldValue('parent_lastname', this.props.getParentResponse.parent_lastname)
        }
      }
      selectClassname(getValue:any){
        const { value } = getValue.target;
        const getGrade = {
            grade:value
        }
        this.setState({getClass:value})
        this.props.fetchMySectionListGet(getGrade);
      }
      selectStandard(e:any){
        const { value } = e.target;
        this.setState({getSection:value})
      }
    render() { 
        const { loading, ListSection } = this.props;  
        const { getClass, getSection } =  this.state;      
        const loadingStudent = { display: loading ? "block" : "none" }; 
        
        if(this.props.getImageURL){
            const getUrlpath = this.props.getImageURL.url;
            if(getUrlpath){
                getUrlpath.forEach((items:any)=>{
                    this.getImage = items;
                })
            }
        }  
        if(getClass && getSection) {
            let postClassesDetails:any = {
                class_name: getClass, 
                section_name: getSection
            }
            this.props.getStudentClassesData(postClassesDetails);
        }
        if(ListSection.length > 0){
            this.getSectionList = false;
        }   
        return (
            <div>
                 <div className="page-wrapper">
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb 
                        titleName={['Student']} 
                        homeName={['Home']} 
                        url={['dashboard']} 
                        baseName={['Student']} 
                        baseURL={['student']} 
                        mainPageTitle={['Add Student']}/>
                        <div className="row">
                        <div className="col-md-12">
                        <div className="card-box">
                        <div className="card-head">
                        <header>Add Student</header>
                        <div className="tools">
                      </div>
                        </div>
                        <div className="card-body">                          
                            <div className="row">
                            <div className="col-md-12">                                                      
                                <Formik
                                    ref={node=>this.formik = node}
                                    validationSchema={StudentValidation}
                                    initialValues={initialAddValues}
                                    onSubmit={(values:any, actions) => { 
                                        const {academicYear} = this.state
                                        const postValue:any = {
                                            phone_number: values.phone_number,
                                            email_id: values.email_id.toLowerCase(),
                                            student_name: values.student_name,
                                            academic_year:academicYear,
                                            grade: values.grade,
                                            standard:values.standard,
                                            parent_firstname: values.parent_firstname,
                                            parent_lastname: values.parent_lastname,
                                            profile_picture: this.getImage,
                                            user_id:'sdad',
                                            gender:values.gender,
                                            date_of_birth:values.date_of_birth
                                        }                                                                      
                                        this.props.addStudent(postValue).then((res:any)=>{
                                            if(this.props.errorMessage && this.props.errorMessage !== null && this.props.errorMessage !== undefined){
                                                this.formik.setFieldError("phone_number", this.props.errorMessage.phone_number);
                                            }
                                            
                                        })
                                    }}>
                                        {({ values, errors, isSubmitting, isValidating, dirty, touched, setFieldValue }) => {
                                            const isStudentEmpty = (!values.student_name 
                                                || !values.grade 
                                                || !values.standard 
                                                || !values.phone_number 
                                                || !values.email_id 
                                                || !values.parent_firstname);
                                        return(
                                        <Form>                                    
                                            <div className="row">
                                            <div className="col-md-6 p-t-20 pl-0 pr-0">
                                            <div className="col-md-12 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Student Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="student_name"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 p-t-20">
                                                <FormControl component="fieldset" error={touched.gender && Boolean(errors.gender)}>
                                                    <FormLabel component="legend">Gender <span className='formmandatorycolor'> *</span></FormLabel>
                                                    <Field component={FormikRadioGroup} name="gender">
                                                    <FormControlLabel value="M" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="F" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="O" control={<Radio />} label="Other" />
                                                    </Field>
                                                    <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                                                </FormControl>
                                                </div>
                                                <div className='col-md-12 p-t-20'>                                                                                                          
                                                    <FormGroup>
                                                        <legend className="MuiFormLabel-root">
                                                            <span>Date of Birth</span>
                                                        </legend>
                                                        <Field
                                                            type="date"
                                                            name="date_of_birth"
                                                            component={TextField}                                                            
                                                            disabled={false}
                                                            InputProps={{
                                                                inputProps: {
                                                                    max: maxDate, // Disable future dates
                                                                },
                                                            }}
                                                        />                                                        
                                                    </FormGroup>                                               
                                                </div>
                                                <div className="col-md-12 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                    <Field 
                                                    label={<><span>Grade</span><span className='formmandatorycolor'> *</span></>}
                                                    name="grade"  
                                                    select
                                                    component={TextField}        
                                                    disabled={false} 
                                                    onChange={(e:any) => {
                                                        setFieldValue('grade', e.target.value)
                                                        this.selectClassname(e);
                                                      }}   
                                                    >
                                                        { this.props.ListClass.map((item:any) =>(
                                                                    <MenuItem value={item.grade_id}>{item.value}</MenuItem>
                                                            ))
                                                        }
                                                    </Field> 
                                                    </FormGroup> 
                                                    </div>
                                                </div>
                                                <div className="col-md-12 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                    <Field 
                                                    label={<><span>Section</span><span className='formmandatorycolor'> *</span></>}
                                                    name="standard"  
                                                    select
                                                    component={TextField}        
                                                    disabled={this.getSectionList} 
                                                    fullwidth 
                                                    onChange={(e:any) => {
                                                        setFieldValue('standard', e.target.value)
                                                        this.selectStandard(e);
                                                      }} 
                                                    >
                                                        { this.props.ListSection.map((item:any) =>(
                                                                    <MenuItem value={item.value}>{item.value}</MenuItem>
                                                            ))
                                                        }
                                                    </Field> 
                                                    </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 p-t-20">
                                                <div className="paddingleft">
                                                <ImageUpload postImage={this.getImage}/>
                                                </div>                                            
                                            </div>                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                    <FormGroup>
                                                            <Field
                                                                label={<><span>Phone Number</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="phone_number"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={false} 
                                                                onChange={(e:any) => {
                                                                    setFieldValue('phone_number', e.target.value)
                                                                    this.findParantDetail(e);
                                                                  }}                                                           
                                                            /> 
                                                    </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>
                                                            <Field
                                                                label={<><span>Email Id</span><span className='formmandatorycolor'> *</span></>}
                                                                type="email"
                                                                name="email_id"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={this.parentName}
                                                               
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label={<><span>Parent First Name</span><span className='formmandatorycolor'> *</span></>}
                                                                type="text"
                                                                name="parent_firstname"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={this.parentName}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 p-t-20">
                                                    <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label txt-full-width pt-0">
                                                        <FormGroup>                                                       
                                                            <Field
                                                                label='Parent Last Name'
                                                                type="text"
                                                                name="parent_lastname"
                                                                component={TextField}
                                                                className="textfield__input"
                                                                disabled={this.parentName}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-right mb-3 mr-2 mt-4">
                                                <Button className="btn btn-pink mr-1 ml-1" 
                                                disabled={isStudentEmpty 
                                                || isValidating 
                                                || !dirty || !!(errors.student_name && touched.student_name) || !!(errors.grade && touched.grade)||
                                                !!(errors.standard && touched.standard) || 
                                                !!(errors.phone_number && touched.phone_number) || 
                                                !!(errors.email_id && touched.email_id) || 
                                                !!(errors.parent_firstname && touched.parent_firstname)} type="submit">Submit</Button>
                                                <Button className="btn btn-default mr-1 ml-1" onClick={this.goBack}>Cancel</Button>
                                            </div>
        
                                    </Form>
                                    )}
                                }
                                </Formik>
                               
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
            <div style={loadingStudent}><SpinnerLoader/></div>
        </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        ListClass:state.classes.my_class,
        ListSection:state.classes.my_section,
        getChangeYearData:state.profile.getYear,
        stopYearDate:state.profile.yearStatusFails,
        loading:state.student.loading,
        errorMessage: state.student.errors,
        getParentResponse:state.student.parentDetails,
        checkParent:state.student.getParentStatus,
        getImageURL:state.student.ImageURL,
        getProfile:state.profile.profileData,
        license: state.license.license,
        licenseBySchool: state.license.licenseBySchool
    };
  };

export default connect(mapStateToProps, { addStudent, 
    fetchGradePost, 
    fetchGradeStandardPost, 
    fetchParent, 
    fetchMyClassListGet, 
    fetchMySectionListGet,
    getStudentClassesData, stopYearData, fetchLicenseBySchoolGet })(AddStudent)
