import { Component } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from "react-router-dom";
import { QuestionBank } from "../../router/Roles";
import { connect } from "react-redux";
import { fetchExamTypeGet, deleteExamType } from "../../store/QuestionBank/Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonLoader from "../../components/CommonLoader";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { CommonModel } from "../../components/CommonModel";
import { formValidationPatten } from "../../services/Constants";

export type OwnExamTypeProps = {
    loading?: boolean
    records?: any
    page: number
    per_page: number
    total: number
    totalPage: number
    fetchExamTypeGet: (params: any) => any;
    deleteExamType: (id: number) => any
}

interface StateTypes {
    records?: any,
    search: string,
    hasMore: boolean,
    page: any,
    page_no: any,
    SortOrderData: string,
    OrderNameData: string,
    showDelete: boolean,
    deleteId: any,
}

export class ExamType extends Component <OwnExamTypeProps, StateTypes> {

    constructor(props: any ){
        super(props)
        this.state={
            records : [],
            search: '',
            hasMore: true,
            page: 1,
            page_no: 1,
            SortOrderData: '',
            OrderNameData: '',
            showDelete: false,
            deleteId:'',
        };
    }
    
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getExamType()
    }
    
    getExamType() {
        const params = {
          page_no: 1,
          search: this.state.search,
          order_by: this.state.OrderNameData,
          sort_by: this.state.SortOrderData,
        }
        this.props.fetchExamTypeGet(params).then((res: any)=>{
            this.setState({ hasMore: true, page:1 })
        })
    }

    public fetchMoreExamTypeData = () => {
        const { page } = this.state;

        if (this.state.hasMore === true) {
            if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
                const params = {
                    page_no: page+ 1,
                    search: this.state.search,
                    order_by: this.state.OrderNameData,
                    sort_by: this.state.SortOrderData,
                }
                this.props.fetchExamTypeGet(params).then((res: any)=>{
                    this.setState({ page: page + 1 })
                })
            }  
            if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
                this.setState({
                  hasMore: false,
                })
            }
        }
    }

    private renderExamType(records: any) {
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return(
            <div>
                <InfiniteScroll
                    dataLength={records.length}
                    next={this.fetchMoreExamTypeData}
                    hasMore={this.state.hasMore}
                    loader={<h4 style={loadingQuestionFull}>Loading...</h4>}
                >
                    <table className="table table-striped custom-table table-hover table-ellipsis">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Total Marks</th>
                                <th>Allowed Time (In Minutes)</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.length > 0 ?
                                records.map((items: any) => (
                                  <tr>
                                    <td title={items['name'] || '-'}>{items['name'] || '-'}</td>
                                    <td>{items['description'] || '-'}</td>
                                    <td>{items['total_marks'] || '-'}</td>
                                    <td>{items['allowed_time'] || '-'}</td>
                                    <td>
                                        {items.uid ? 
                                            <Link to={`/examtype/edit/${items.uid}`}>
                                                <button className="btn btn-primary btn-xs" title="Edit">
                                                    <i className="fa fa-pencil" aria-hidden="true" />
                                                </button>
                                            </Link> :
                                            <button className="btn btn-primary btn-xs" title="Edit">
                                                <i className="fa fa-pencil" aria-hidden="true" />
                                            </button>
                                        }
                                    </td>
                                    <td>
                                        {items.uid ?
                                            <button className="btn btn-primary btn-xs"
                                                title="Delete"><i className="fa fa-trash" 
                                                aria-hidden="true"
                                                onClick={() => this.showDeletePopup(items.uid)}
                                            ></i></button> :
                                            <button className="btn btn-disable btn-xs"
                                                title="Delete"><i className="fa fa-trash" aria-hidden="true"
                                            ></i></button>
                                            }
                                    </td>
                                  </tr>  
                                ))
                            : <CommonLoader />}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        )
    }

    showDeletePopup (getData: any) {
        if(getData){
            this.setState({ showDelete: true, deleteId: getData});
        }
    }

    public hideExamTypeDelete = () => {
        this.setState({ showDelete: false });
    }

    public handleDelete = () => {
        this.setState({ showDelete: false, page: 1, hasMore: true });
        this.props.deleteExamType(this.state.deleteId).then((res:any)=>{
            this.getExamType()
        })
    }

    handleInputChange = (e: any) => {
        const { value } = e.target;
        const getSearchValue = value;
        var intRegex = formValidationPatten.alphanumericTest;
        if(intRegex.test(getSearchValue) || getSearchValue === ''){
           if (getSearchValue) {
          const postSearchValue:any = {
            page_no: 1,
            search: getSearchValue,
          }
          this.props.fetchExamTypeGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: getSearchValue
          })
        } else {
          const postSearchValue:any = {
            page_no: 1,
            search: '',
          }      
          this.props.fetchExamTypeGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: ''
          })
        }}
    }

    refreshExamTypeTable = () =>{
        const postSearchValue:any = {
            page_no: 1,
            search: '',
        }
        this.setState({
          search: '',
          SortOrderData:'',
          OrderNameData:'',
        }, () => {
            this.props.fetchExamTypeGet(postSearchValue)
        });  
    }

    render(){
        const { loading, records } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const { showDelete } = this.state;
        const postValue:any = {
            title:'Please Confirm',
            action_status:'delete',
            action_name:'Exam Type'
        }
        return(
            <div>
                 <CommonModel
                    onState={showDelete}
                    currentState={postValue}
                    onConfirm={this.handleDelete}
                    onCancel={this.hideExamTypeDelete}
                />
                <div className="page-wrapper" >
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb 
                                titleName={['Exam Type']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Exam Type']}
                            />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-topline-red">
                                        <div className="card-head">
                                            <header>
                                                <Link to={QuestionBank.AddExamType} className="btn btn-pink ml-2 mt-1">
                                                    Create Exam Type
                                                </Link>
                                            </header>
                                            <div className="tools">
                                                <div className="d-flex">
                                                    <div>
                                                        <input
                                                            placeholder="Search"
                                                            name="search"
                                                            value={this.state.search}
                                                            className="form-control"
                                                            onChange={this.handleInputChange}                         
                                                        />
                                                    </div>
                                                    <div className="ml-2 mt-2">
                                                        <span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshExamTypeTable}></span>
                                                        <span className="t-collapse btn-color fa fa-chevron-down pointer" ></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body no-padding height-9">
                                            <div className="row">
                                                <div className="table-responsive">
                                                    {this.renderExamType(records)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={loadingQuestionFull}><SpinnerLoader /></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return{
        loading: state.QuestionBank.loading,
        records: state.QuestionBank.records,
        page: state.QuestionBank.page,
        per_page: state.QuestionBank.per_page,
        total: state.QuestionBank.total,
        totalPage: state.QuestionBank.totalPage
    }
}

const mapDispatchToProps = {
    fetchExamTypeGet,
    deleteExamType
  }

export default connect( mapStateToProps, mapDispatchToProps )(ExamType)