import React, { Component } from 'react'
import { connect } from 'react-redux'
import BreadCrumb from '../../components/BreadCrumb';
import { getQuizReport } from '../../store/dashboard/Actions';
import CommonLoader from '../../components/CommonLoader';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import {getAllSchoolNameList} from '../../store/usermanage/Actions';
import { RouteComponentProps } from 'react-router';
import history from '../../History';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { formValidationPatten } from '../../services/Constants';
import { getQuizzesReportData } from '../../store/profile/Actions';
import Cookies from 'universal-cookie';
import { fetchProfilePost, stopYearData, YearListGet } from '../../store/profile/Actions';
import {PropsTypes} from './Types';

export interface PostsQuizzesProps extends RouteComponentProps<OwnPropsParams> {
    getYear?:any,
    getQuizReport:(postValue:any) => any;
    getAllSchoolNameList: () => any;
    getQuizzesReportData:(postValue:any) => any;
    fetchProfilePost:()=>any;
    stopYearData:()=>any;
    YearListGet: (postValue?: any) => any;
    loading: boolean,
    loginProfile:any,
    getQuizzesReport:any,
    total:any;
    getSchoolList:any;
    pageQuiz:any;
    perPageQuiz:any;
    stopYear?:any;
    getStudentInfoDetails?:any
}

export class Quizzes extends Component<PostsQuizzesProps, PropsTypes> {
    cookies:any = new Cookies();
    getSchoolPropsValue:any;
    constructor(props: any) {
        super(props);
        this.state = {
            getCorrectSchoolId:this.props.match.params.id || null,
            getCurrectYear: null,
            hasMore: true,
            prev: 0,
            next: 10,
            acsOrder: true,
            descOrder: false,
            page: 1,
            per_page: 10,
            records: [],
            total: 0,
            totalPage: 0,
            search: '',
            SortOrderData:'',
            OrderNameData:'',
            sort_by:'desc',
            sort_class_name:'fa fa-sort-amount-asc',
            sort_title_name:'Ascending order'
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        
        this.getUpdateYear()        
    }
    componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getUpdateYear(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
    getUpdateYear(getYear?:any){
        if(getYear && getYear !== undefined && getYear !== null){
            this.setState({getCurrectYear:getYear})
            this.getQuizzesRecord(getYear)
          }else {
            this.setState({getCurrectYear:this.props.getYear})
            this.getQuizzesRecord(this.props.getYear)
          }
    }
    getQuizzesRecord(getYear?:any){
        const {loginProfile} = this.props;
        if(loginProfile){
            const getUserType:any = loginProfile.usertype;
            if(getUserType === 1){
                this.getSChoolNameList(getYear);
            } else {
                this.quizzesReportAcademic(getYear);  
            }
        } 
    }
    getSChoolNameList(getYear?:any){
        this.props.getAllSchoolNameList().then((res:any)=>{
            const { getSchoolList } = this.props;
            const {getCorrectSchoolId} = this.state;
            if(getSchoolList && getSchoolList !== null && getSchoolList !== undefined && getSchoolList.length > 0){
                if(this.props.location.state){
                    let schoolInfo :any = this.props.history.location.state;
                    if(schoolInfo.isFromFeedback === true){
                        const { getStudentInfoDetails } = this.props;
                        window.history.pushState({}, "", window.location.pathname);
                        this.setState({getCorrectSchoolId: getStudentInfoDetails.school_id});
                    }else{
                        this.setState({getCorrectSchoolId: getSchoolList[0].id})
                    }
                }else{
                    if(!this.props.match.params.id){
                        this.setState({getCorrectSchoolId: getSchoolList[0].id})
                    }
                }
                    if(this.props.match.params.id){
                        this.QuizzesListAcademic(getYear, this.props.match.params.id);
                    }else{
                        this.QuizzesListAcademic(getYear);
                    }
            }else{
                this.QuizzesListAcademic(getYear, getCorrectSchoolId);
                this.setState({getCorrectSchoolId: getCorrectSchoolId})
            }
           
        });
    }
    QuizzesListAcademic(getYear?:any, schoolId?:any){
        const {getCorrectSchoolId} = this.state;
        this.getSchoolPropsValue =  this.props.match.params.id;
        if(schoolId){
            const postValue = {
                page_no:1,
                academic_year:getYear,
                school_id: schoolId
            }
            this.props.getQuizReport(postValue).then((res:any)=>{
                window.scrollTo(0, 0);
                this.setState({hasMore:true, page:1})
            });
            this.props.YearListGet({school_id:schoolId});
        }else if(getCorrectSchoolId) {
            const postValue = {
                page_no:1,
                academic_year:getYear,
                school_id: getCorrectSchoolId
            }
            this.props.getQuizReport(postValue).then((res:any)=>{
                window.scrollTo(0, 0);
                this.setState({hasMore:true, page:1})
            });
            this.props.YearListGet({school_id:getCorrectSchoolId});
        }
    }
    quizzesReportAcademic(getYear?:any) {
            const postValue = {
                page_no:1,
                academic_year:getYear
            }
            this.props.getQuizReport(postValue).then((res:any)=>{
                window.scrollTo(0, 0);
                this.setState({hasMore:true, page:1})
            });
    }
    getFilterQuizze = (e:any) =>{
        const { value } = e.target;
        const {getCurrectYear} = this.state;
        if(value){
            const postValue = {
                page_no:1,
                academic_year: getCurrectYear,
                school_id:value
            }
            this.props.getQuizReport(postValue);
            this.props.YearListGet({school_id:value});
            this.setState({getCorrectSchoolId: value})
        }
    }
    getQuizzesResult = (getValue:any) =>{
        const postValue:any = {
            getQuizzesDetails:getValue,
            school_id:this.state.getCorrectSchoolId
        }
        history.push({
			pathname: `/quizzes_report/${getValue.quiz_id}`,
          });
        this.props.getQuizzesReportData(postValue)
    }
    
  handleQuizzesChange = (e: any) => {
    const { value } = e.target;
    const {getCurrectYear} = this.state;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
      if (getSearchValue) {
        const postSearchValue = {
          page_no: 1,
          search: getSearchValue,
          academic_year:getCurrectYear,
          school_id: this.state.getCorrectSchoolId
        }
        this.props.getQuizReport(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue
        })
      } else {
        const postSearchValue = {
          page_no: 1,
          search: '',
          academic_year:getCurrectYear,
          school_id: this.state.getCorrectSchoolId
        }
        this.props.getQuizReport(postSearchValue);
        this.setState({
          hasMore: true,
          page: 1,
          search: ''
        })
      }
    }
  
  }
  public showFilterData = (getdata: any) => {
      const {getCorrectSchoolId, getCurrectYear} = this.state;
    if (getdata.sort_by === 'desc') {
      this.setState({ acsOrder: false })
      const postValue = {
        page_no: 1,
        academic_year:getCurrectYear,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        search: this.state.search,
        school_id:getCorrectSchoolId
      }
      this.props.getQuizReport(postValue);
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'asc',
        sort_class_name:'fa fa-sort-amount-desc',
        sort_title_name:'Descending order'
      })
    } else {
      this.setState({ acsOrder: true })
      const postValue = {
        page_no: 1,
        academic_year:getCurrectYear,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        search: this.state.search,
        school_id:getCorrectSchoolId
      }
      this.props.getQuizReport(postValue);
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'desc',
        sort_class_name:'fa fa-sort-amount-asc',
        sort_title_name:'Ascending order'
      })
    }
  }
  public fetchMoreQuizzes = () => {
    const {getCurrectYear} = this.state;
          if (this.state && this.state.hasMore === true) {
              if (Math.ceil(this.props.total / this.props.perPageQuiz) > this.props.pageQuiz) {
                  const postValue = {
                      page_no: this.state.page + 1,
                      search: this.state.search,
                      sort_by: this.state.SortOrderData,
                      order_by: this.state.OrderNameData,
                      academic_year: getCurrectYear,
                      school_id: this.state && this.state.getCorrectSchoolId?this.state.getCorrectSchoolId:0
                  }
                  console.log(postValue)
                  this.props.getQuizReport(postValue);
                  this.setState({
                      page: this.state.page + 1
                  })
              }
              if (Math.ceil(this.props.total / this.props.perPageQuiz) === this.props.pageQuiz) {
                  this.setState({
                      hasMore: false,
                  })
              }
          }
  }
  refreshQuizzesTable = () => {
    const {getCurrectYear} = this.state;

    const {loginProfile} = this.props;
    if(loginProfile){
        const getUserType:any = loginProfile.usertype;
        if(getUserType === 1){
            this.setState({
                search: '',
              }, () => {
                this.QuizzesListAcademic(getCurrectYear)
              });  
        } else {
            this.setState({
                search: '',
            }, () => {
                this.quizzesReportAcademic(getCurrectYear)
            });  
        }
    }
  }
    render() {
        const { loading, getQuizzesReport, total, getSchoolList } = this.props;
        const { getCorrectSchoolId, sort_by, sort_class_name, sort_title_name, search } = this.state;
        const loadingTextCSS = { display: loading ? "block" : "none" };
        let academicAdminUserTypes:any = false;
        const getUserType:any = this.props.loginProfile.usertype;
        if(getUserType === 1){
            academicAdminUserTypes = true;
        }
        const getSchoolListData:any = getSchoolList;
        let SchoolListAdd:any;
        if(getSchoolList){
            SchoolListAdd = getSchoolListData;
        }
        return (
            <div>
                <div className="page-wrapper">
                    <div className="page-content-wrapper">
                        <div className="page-content pt-3">
                            <BreadCrumb
                                titleName={['Quizzes']}
                                homeName={['Home']}
                                url={['dashboard']}
                                mainPageTitle={['Quizzes']} />
                                    {academicAdminUserTypes ?
                                <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-5"></div>
                                    {SchoolListAdd?
                                    <div className="col-md-3 mb-4 pull-right">                                       
									<select name="classlist" className="form-control" value={getCorrectSchoolId} onChange={this.getFilterQuizze}>
										{SchoolListAdd.map((items: any) => (
											<option value={items.id}> {items.school_name} </option>
										))}
									</select>
                                    </div>
                                    :null}
                                </div>
                                : null}
                            <div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                <div className="mr-4 mt-2">
                                    <h4 className="pull-right">Total: {total}</h4>
                                </div>
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card card-topline-red">
                                            <div className="card-head" >
                                                <header>
                                                </header>
                                                <div className="tools">
                                                <div className="d-flex">
                            <div>
                            <input
                            placeholder="Search"
                            name="search"
                            value={search}
                            className="form-control"
                            onChange={this.handleQuizzesChange}
                          />
                            </div>
                            <div className="ml-2 mt-1">
                                <span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshQuizzesTable}></span>
                                <span className="t-collapse btn-color fa fa-chevron-down pointer" ></span>
                            </div>
                          </div>
                                                </div>
                                            </div>
                                            <div className="card-body no-padding height-9">
                                                <div className="table-responsive">
                                                <InfiniteScroll
                                                        dataLength={this.props.getQuizzesReport.length}
                                                        next={this.fetchMoreQuizzes}
                                                        hasMore={this.state.hasMore}
                                                        loader={<h4 style={loadingTextCSS}>Loading...</h4>}
                                                    >
                                                    <table className="table table-striped custom-table table-hover">
                                                        <thead>
                                                            <tr>
                                                            <th>
                                                                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'question_set_name' })}
                                                                title={sort_title_name} className="headerBold">Name&nbsp;
                                                                <i className={sort_class_name} aria-hidden="true"></i></button>
                                                            </th>
                                                            <th>
                                                                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'class_name' })}
                                                                title={sort_title_name} className="headerBold">Class Name&nbsp;
                                                                <i className={sort_class_name} aria-hidden="true"></i></button>
                                                            </th>
                                                            <th>
                                                                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'subject_name' })}
                                                                title={sort_title_name} className="headerBold">Subject &nbsp;
                                                                <i className={sort_class_name} aria-hidden="true"></i></button>
                                                            </th>
                                                            <th>
                                                                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'performance' })}
                                                                title={sort_title_name} className="headerBold">Performance (Average)&nbsp;
                                                                <i className={sort_class_name} aria-hidden="true"></i></button>
                                                            </th>
                                                            <th>
                                                                <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'time' })}
                                                                title={sort_title_name} className="headerBold">Time&nbsp;
                                                                <i className={sort_class_name} aria-hidden="true"></i></button> 
                                                            </th>
                                                            </tr>
                                                        </thead>
                                                        {getQuizzesReport && getQuizzesReport !== null && getQuizzesReport !== undefined && getQuizzesReport.length > 0? 
                                                        <tbody>
                                                            {
                                                                getQuizzesReport.map((items: any, index: any) => (
                                                                    <tr key={index}>
                                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesResult(items)}>{items.question_set_name}</Link></td>
                                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesResult(items)}>{items.class_name}</Link></td>
                                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesResult(items)}>{items.subject_name}</Link></td>
                                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesResult(items)}>{items.performance}</Link></td>
                                                                        <td><Link to={`#`} onClick={()=> this.getQuizzesResult(items)}>{items.time}</Link></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                        :<CommonLoader/>}
                                                    </table>
                                                </InfiniteScroll>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingTextCSS}><SpinnerLoader /></div>
            </div>
        )
    }
}
interface OwnPropsParams {
    id: string;
}
const mapStateToProps = (state: any) => {
    return {
        loginProfile:state.profile.profileData,
        getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
        getQuizzesReport:state.dashboard.recordsQuizReport,
        loading:state.dashboard.loading,
        total:state.dashboard.totalQuizReport,
        getSchoolList:state.userManage.category,
        pageQuiz:state.dashboard.pageQuizReport,
        perPageQuiz:state.dashboard.perPageQuizReport,
        getStudentInfoDetails: state.profile.AttendanceStudentView
    }
}

export default connect(mapStateToProps, {getQuizReport, 
    getAllSchoolNameList, getQuizzesReportData, fetchProfilePost, stopYearData, YearListGet})(Quizzes)
