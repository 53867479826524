import React from 'react';
import { connect } from 'react-redux';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import { UserRoles } from '../../services/Constants';
import { fetchUserPost, deletePost } from '../../store/usermanage/Actions';
import { UserManageTypes, ISearchBarState } from '../../store/usermanage/Type';
import { LoadMoreType } from '../../components/type';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import history from '../../History';
import Switch from '@material-ui/core/Switch';
import { formValidationPatten } from '../../services/Constants';
import {CommonModel} from '../../components/CommonModel';
import { stopYearData } from '../../store/profile/Actions'

export interface PostsListProps {
  getYear?:any,
  loading: boolean;
  loginProfile:any;
  userDetails: UserManageTypes;
  deleteDetails: any,
  fetchUserPost: (getData: LoadMoreType, typeId?:string) => any;
  deletePost: (subjectDetailDelete: number) => any;
	stopYearData:() => any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
}

class UserManagement extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteUserManageId: [],
      userManageData: [],
      hasMore: true,
      prev: 0,
      next: 10,
      acsOrder: true,
      page: 1,
      search: '',
      SortOrderData: '',
      OrderNameData: '',
      role:'School Admin',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      academicYear:null
    };
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.getUpdateYear();
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.getUpdateYear(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear, deleteDetails} = this.props;
    if(deleteDetails === true) {
      this.checkStatusProcess()
    }
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
  getUpdateYear(getYearData?:any){
    if(getYearData && getYearData !== undefined && getYearData !== null){
      this.setState({academicYear:getYearData})
      this.getUserManagement(getYearData)
    }else {
      this.setState({academicYear:this.props.getYear})
      this.getUserManagement(this.props.getYear)
    }
  }
  getUserManagement(getYearData?:any){
    const postValue = {
      page_no: 1,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      role: this.state.role,
      academic_year:getYearData
    }
    this.props.fetchUserPost(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
    });;
  }

  checkStatusProcess() {
    const {academicYear} = this.state;
    const selectDataList = this.props.deleteDetails;
    if (selectDataList === true) {
      const postValue = {
        page_no: 1,
        search: this.state.search,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData,
        role: this.state.role,
        academic_year:academicYear
      }
      this.props.fetchUserPost(postValue).then((res:any)=>{
        window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
      });
    }
  }

  public handleUserDelete = () => {
    this.props.deletePost(this.state.deleteUserManageId).then((res:any)=>{
      window.scrollTo(0, 0);
    });
    this.setState({ showDelete: false, page: 1, hasMore:true });
  }

  public hideUserDelete = () => {
    this.setState({ showDelete: false })
  }
  public showUserDelete = (getData: any) => { 
    let postValue:any
    let titleClassMessage: any;
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'User'
      }
      this.setState({ showDelete: true, deleteUserManageId: getData, postData:postValue });
    }
  }

  public showFilterData = (getdata: any) => {
    const {academicYear} = this.state;
    if (getdata.sort_by === 'desc') {
      this.setState({ acsOrder: false })
      const postValue = {
        page_no: 1,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        role: this.state.role,
        academic_year:academicYear
      }
      this.props.fetchUserPost(postValue);
      this.setState({
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        hasMore:true,
        page: 1,
        sort_by:'asc',
        sort_class_name:'fa fa-sort-amount-desc',
        sort_title_name:'Descending order'
      })
    } else {
      this.setState({ acsOrder: true })
      const postValue = {
        page_no: 1,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        role: this.state.role,
        academic_year:academicYear
      }
      this.props.fetchUserPost(postValue);
      this.setState({
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        hasMore:true,
        page: 1,
        sort_by:'desc',
        sort_class_name:'fa fa-sort-amount-asc',
        sort_title_name:'Ascending order'
      })
    }
  }
  checkUserType() {
    const {loginProfile} = this.props;
    const getUserToken:any = String(loginProfile.usertype);
    if(getUserToken){
    if (getUserToken === UserRoles.acadamicAdmin) {
      this.setState({ acadamicAdmin: true })
    } else if (getUserToken === UserRoles.schoolAdmin) {
      this.setState({ schoolAdmin: true })
    } else if (getUserToken === UserRoles.teacher) {
      this.setState({ teacher: true })
    } else if (getUserToken === UserRoles.parent) {
      this.setState({ parent: true })
    }
  }
  }


  public fetchMoreUserData = () => {
    const {academicYear} = this.state;
    if (this.state.hasMore === true) {
      if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
        const postValue = {
          page_no: this.state.page + 1,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          role: this.state.role,
          academic_year:academicYear
        }
        this.props.fetchUserPost(postValue);
        this.setState({
          page: this.state.page + 1
        })
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
          page: this.state.page
        })
      }
    }
  }

  handleInputUserChange = (e: any) => {
    const { academicYear } = this.state;
    const { value } = e.target;
    const getSearchValue = value || "";
    var intRegex = formValidationPatten.alphanumericTest;
    if (intRegex.test(getSearchValue) || getSearchValue === "") {
      const postSearchValue = {
        page_no: 1,
        search: getSearchValue,
        role: this.state.role,
        academic_year: academicYear,
      };

      this.props.fetchUserPost(postSearchValue, 'userManagement').then((res: any) => {
        this.setState({
          hasMore: true,
          page: 1,
          search: getSearchValue,
        });
      });
    }
  };

  private renderUserManageView(getUserResponseData: any) {
    const { loading } = this.props;
    const {sort_title_name, sort_by, sort_class_name} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getUserToken = localStorage.getItem('token');
    if (!getUserToken) {
      history.push("/");
    }    
    return (
      <div>
        <InfiniteScroll
          dataLength={getUserResponseData.length}
          next={this.fetchMoreUserData}
          hasMore={this.state.hasMore}
          loader={<h4 style={loadingTextCSS}>Loading...</h4>}
        >
          <table className="table table-striped custom-table table-hover">
            <thead>
              <tr>
                <th>
                    <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'firstname' })}
                      title={sort_title_name} className="headerBold">
                      User Name <i className={sort_class_name} aria-hidden="true"></i></button> 
                </th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'email_id' })}
                    title={sort_title_name} className="headerBold">Email Id <i className={sort_class_name} aria-hidden="true"></i></button></th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'phone_number' })}
                    title={sort_title_name} className="headerBold">Phone Number <i className={sort_class_name} aria-hidden="true"></i></button></th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'school_name' })}
                    title={sort_title_name} className="headerBold">School Name <i className={sort_class_name} aria-hidden="true"></i></button> </th>
                <th>
                  <button onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'role' })}
                    title={sort_title_name} className="headerBold">Role <i className={sort_class_name} aria-hidden="true"></i></button></th>
                <th className="UserTable">Actions</th>
              </tr>
            </thead>
            <tbody>
              {getUserResponseData.length > 0 ?
                getUserResponseData.map((data: any, index: number) => (
                  <tr key={data['id']}>
                    <td >{data.firstname || '-'} {data.lastname}</td>
                    <td >{data['email_id'] || '-'}</td>
                    <td >{data['phone_number'] || '-'}</td>
                    <td >{data['school_name'] || '-'}</td>
                    <td >{data['role'] || '-'}</td>
                    { data.school_status?
                    <td>
                    {data.is_active ?
                      <Link to={"/edit_user/" + data.ldap_id}>
                        <button className="btn btn-primary btn-xs"
                          title="Edit User"><i className="fa fa-pencil" aria-hidden="true" ></i></button></Link> :
                      <button className="btn btn-disable btn-xs"
                        title="Edit User"><i className="fa fa-pencil" aria-hidden="true" ></i></button>}
                         {data.is_active ?
                      <Switch
                      checked={data.is_active}
                      onClick={() => this.showUserDelete({ ldapId: String(data['ldap_id']), isActive: false })}
                      name="checkedUser"
                      inputProps={{ 'aria-label': 'success checkbox' }}
                      title="Deactivate User"
                    /> :
                    <Switch
                    checked={data.is_active}
                    onClick={() => this.showUserDelete({ ldapId: String(data['ldap_id']), isActive: true })}
                    name="checkedUser"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    title="Activate User"
                  />}
                  </td>
                    :
                    <td title="School is deactivated">
                          <button className="btn btn-disable btn-xs"
                          title="School is deactivated"><i className="fa fa-pencil" aria-hidden="true" ></i></button>
                          <Switch disabled inputProps={{ 'aria-label': 'disabled checkbox' }} title="School is deactivated" />
                    </td>
                    }
                    
                  </tr>
                ))
                : <CommonLoader />}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>

    )
  }
  refreshUserManageTable = () => {
    this.getUserManagement()
  }
  render() {
    const { loading } = this.props;
    const { showDelete, postData } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const userTotal = this.props.total;  
    return (
      <div>
        <CommonModel
        onState={showDelete}
        currentState={postData}
        onConfirm={this.handleUserDelete}
        onCancel={this.hideUserDelete}
        />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb
                titleName={['User']}
                homeName={['Home']}
                url={['dashboard']}
                mainPageTitle={['User']} />
                <div className="row">
                  <div className="col-md-12 mb-3">
                  <div className="mr-4 mt-2">
                      <h4 className="pull-right">Total: {userTotal}</h4>
                    </div>
                  </div>
                </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-topline-red">
                    <div className="card-head">
                      <header>
                        <Link to="/add_user">
                          <button className="btn btn-pink">Add User</button>
                        </Link>
                      </header>
                      <div className="tools">
                      <div className="d-flex mt-2">
										    <div>
                        <input
                            placeholder="Search"
                            name="search"
                            className="form-control"
                            pattern="[a-zA-Z0-9_.-]"
                            onChange={this.handleInputUserChange}
                          />
										    </div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshUserManageTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-down pointer" ></span>
										</div>
									</div>
                          
                      </div>
                    </div>
                    <div className="card-body no-padding height-9">
                      <div className="row">
                        <div className="table-responsive">
                          {this.renderUserManageView(this.props.records)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    getYear:state.profile.getYear,
    stopYear:state.profile.yearStatusFails,
    loading: state.userManage.loading,
    loginProfile:state.profile.profileData,
    userDetails: state.userManage.items,
    total: state.userManage.total,
    per_page: state.userManage.per_page,
    records: state.userManage.records,
    page: state.userManage.page,
    totalPage: state.userManage.totalPage,
    deleteDetails: state.userManage.isAuthenticated
  };
};
export default connect(
  mapStateToProps, { fetchUserPost, deletePost, stopYearData }
)(UserManagement);