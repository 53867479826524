import React, { Component } from "react";
import { connect } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import { Link } from 'react-router-dom';
import { QuestionBank } from '../../router/Roles';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommonLoader from '../../components/CommonLoader';
import { fetchBluePrintGet, deleteBluePrintById } from "../../store/QuestionBank/Actions";
import SpinnerLoader from "../../components/spinner/SpinnerLoader";
import { CommonModel } from "../../components/CommonModel";
import { formValidationPatten } from "../../services/Constants";


export type Props = {
    loading?: boolean
    records?: any
    page: number
    per_page: number
    total: number
    totalPage: number
    fetchBluePrintGet: (data: any) => any;
    deleteBluePrintById: (id: number) => any;
}

interface StateTypes {
    records: any;
    search: string,
    hasMore: boolean,
    page: any,
    page_no: any,
    SortOrderData: string,
    OrderNameData: string,
    showDelete: boolean,
    deleteId: any,
}
export class BluePrintGrid extends Component<Props, StateTypes> {

    constructor(props: any) {
        super(props);
        this.state = {
            records: [],
            search: '',
            hasMore: true,
            page: 1,
            page_no: 1,
            SortOrderData: '',
            OrderNameData: '',
            showDelete: false,
            deleteId:'',
        }
    }
    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.getBluePrintDetails()
    }

    getBluePrintDetails() {
        const params = {
          page_no: 1,
          search: this.state.search,
          order_by: this.state.OrderNameData,
          sort_by: this.state.SortOrderData,
        }
        this.props.fetchBluePrintGet(params).then((res: any)=>{
            this.setState({ hasMore: true, page:1 })
        })
    }


    public fetchMoreStudentData = () => {
        const { page } = this.state;
        if (this.state.hasMore === true) {
            if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {
                const params = {
                    page_no: page+ 1,
                    search: this.state.search,
                    order_by: this.state.OrderNameData,
                    sort_by: this.state.SortOrderData,
                }
                this.props.fetchBluePrintGet(params).then((res: any)=>{
                    this.setState({ page: page + 1 })
                })
            }  
            if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
                this.setState({
                  hasMore: false,
                })
            }
        }
    }

    private renderBluePrint(records: any) {
        const { loading } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        return (
            <div>
                <InfiniteScroll
                    dataLength={records.length}
                    next={this.fetchMoreStudentData}
                    hasMore={this.state.hasMore}
                    loader={<h4 style={loadingQuestionFull}>Loading...</h4>}
                >
                    <table className="table table-striped custom-table table-hover">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>BluePrint Name</th>
                                <th>Grade</th>
                                <th>Subject</th>
                                <th>Exam Type</th>
                                <th>Marks</th>
                                <th>Edit</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.length > 0 ?
                                records.map((items: any) => (
                                    <tr>
                                        <td >{items['uid'] || '-'}</td>
                                        <td >{items['blueprint_name'] || '-'}</td>
                                        <td >{items['internal_grade_name'] || '-'}</td>
                                        <td >{items['internal_subject_name'] || '-'}</td>
                                        <td >{items['exam_type_name'] || '-'}</td>
                                        <td >{items['exam_total_marks'] || '-'}</td>
                                        <td>
                                            {items.uid && items.questionpaper_is_active == false ?
                                                <Link to={`/blueprint/edit/${items.uid}`}>
                                                    <button className="btn btn-primary btn-xs"
                                                        title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button></Link> :
                                                <button className="btn btn-disable btn-xs"
                                                    title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                            }
                                        </td>
                                        <td>
                                            {items.uid ?
                                                <Link to={`/viewblueprint/${items.uid}`}>
                                                    <button className="btn btn-primary btn-xs"
                                                        title="View"><i className="fa fa-eye" aria-hidden="true"></i></button></Link> :
                                                <button className="btn btn-disable btn-xs"
                                                    title="View"><i className="fa fa-eye" aria-hidden="true"></i></button>
                                            }
                                        </td>
                                        <td>
                                        {items.uid ?
                                            <button className="btn btn-primary btn-xs"
                                                title="Delete"><i className="fa fa-trash" 
                                                aria-hidden="true"
                                                onClick={() => this.showDeletePopup(items.uid)}
                                            ></i></button> :
                                            <button className="btn btn-disable btn-xs"
                                                title="Delete"><i className="fa fa-trash" aria-hidden="true"
                                            ></i></button>
                                            }
                                    </td>
                                    </tr>
                                ))
                                : <CommonLoader />}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        )
    }

    showDeletePopup (getData: any) {
        if(getData){
            this.setState({ showDelete: true, deleteId: getData});
        }
    }

    public hideBluePrintDelete = () => {
        this.setState({ showDelete: false });
    }

    public handleDelete = () => {
        this.setState({ showDelete: false, page: 1, hasMore: true });
        this.props.deleteBluePrintById(this.state.deleteId).then((res:any)=>{
            this.getBluePrintDetails()
        })
    }

    handleInputChange = (e: any) => {
        const { value } = e.target;
        const getSearchValue = value;
        var intRegex = formValidationPatten.alphanumericTest;
        if(intRegex.test(getSearchValue) || getSearchValue === ''){
           if (getSearchValue) {
          const postSearchValue:any = {
            page_no: 1,
            search: getSearchValue,
          }
          this.props.fetchBluePrintGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: getSearchValue
          })
        } else {
          const postSearchValue:any = {
            page_no: 1,
            search: '',
          }      
          this.props.fetchBluePrintGet(postSearchValue);
          this.setState({
            hasMore: true,
            page: 1,
            search: ''
          })
        }
        }
      }

      refreshBluePrintTable = () =>{
        const postSearchValue:any = {
            page_no: 1,
            search: '',
        }
        this.setState({
          search: '',
          SortOrderData:'',
          OrderNameData:'',
        }, () => {
            this.props.fetchBluePrintGet(postSearchValue)
        });  
    }

    render() {
        const { loading, records } = this.props;
        const loadingQuestionFull = { display: loading ? "block" : "none" };
        const { showDelete } = this.state;
        const postValue:any = {
            title:'Please Confirm',
            action_status:'delete',
            action_name:'Blue Print'
        }
        return (
            <div className="page-wrapper">
                  <CommonModel
                    onState={showDelete}
                    currentState={postValue}
                    onConfirm={this.handleDelete}
                    onCancel={this.hideBluePrintDelete}
                />
                <div className="page-content-wrapper">
                    <div className="page-content pt-3">
                        <BreadCrumb
                            titleName={['BluePrint']}
                            homeName={['Home']}
                            url={['dashboard']}
                            mainPageTitle={['BluePrint']} />

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-topline-red">
                                    <div className="card-head">
                                        <header>
                                            <Link to={QuestionBank.AddBluePrint} className="ml-2 mt-1">
                                                <button className="btn btn-pink">Add BluePrint</button>
                                            </Link>
                                        </header>
                                        <div className="tools">
                                            <div className="d-flex">
                                                <div>
                                                    <input
                                                        placeholder="Search"
                                                        name="search"
                                                        value={this.state.search}
                                                        className="form-control"
                                                        onChange={this.handleInputChange}                         
                                                    />
                                                </div>
                                                <div className="ml-2 mt-2">
                                                    <span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshBluePrintTable}></span>
                                                    <span className="t-collapse btn-color fa fa-chevron-down pointer" ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body no-padding height-9">
                                        <div className="row">
                                            <div className="table-responsive">
                                                {this.renderBluePrint(records)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={loadingQuestionFull}><SpinnerLoader /></div>
            </div>
        )
    }
}



const mapStateToProps = (state: any) => {
    return {
        loading: state.QuestionBank.loading,
        records: state.QuestionBank.records,
        page: state.QuestionBank.page,
        per_page: state.QuestionBank.per_page,
        total: state.QuestionBank.total,
        totalPage: state.QuestionBank.totalPage
    }
}

export default connect(mapStateToProps, {
    fetchBluePrintGet,
    deleteBluePrintById
})(BluePrintGrid)