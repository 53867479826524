import { Reducer } from 'redux'
import { PsychometricActionTypes, PsychometricState } from './Types'

export const initialState: PsychometricState = {
    items: [],
    loading: false,
    isAuthenticated: null,
    modelPop: false,
    errors: [],
    page: 1,
    per_page: 10,
    records: [],
    total: 0,
    getSchoolEdit: [],
    ageList: [],
    questionnaireitems: [],
    questionnaireMappingDetails: [],
    schoolCategoryList: null,
    psychometricReport: [],
    activeClassTeacher: [],
    addQuestionnaireMapping: [],
    student_list: [],
    questionnareLoading: false,
    questionnaireStudentRecords:[]
}

const reducer: Reducer<PsychometricState> = (state = initialState, action) => {
    switch (action.type) {
        case PsychometricActionTypes.FETCH_AGE:
        case PsychometricActionTypes.FETCH_QUESTIONNAIRE:
        case PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING:
        case PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID:
        case PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER:
        case PsychometricActionTypes.ADD_QUESTIONNAIRE:
        case PsychometricActionTypes.EDIT_QUESTIONNAIRE:
        case PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE:
        case PsychometricActionTypes.DELETE_QUESTIONNAIRE:
            case PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS:
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
                errors: [],
                schoolCategoryList: null
            };
        case PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID:
            return {
                ...state,
                questionnareLoading: true,
                isAuthenticated: false,
                errors: [],
                schoolCategoryList: null
            };
        case PsychometricActionTypes.FETCH_QUESTIONNAIRE_SUCCESS:
            const { payload } = action
            return {
                ...state,
                loading: false,
                questionnaireitems: payload.data,
                isAuthenticated: false,
            };
        case PsychometricActionTypes.FETCH_AGE_SUCCESS:
            return {
                ...state,
                loading: false,
                ageList: action.payload.data,
                isAuthenticated: false,
            };
        case PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                questionnareLoading: false,
                questionnaireMappingDetails: action.payload.data,
                isAuthenticated: false,
            };
        case PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_SUCCESS:
            const { records, per_page, page, total } = action;
            if (page === 1) {
                state.records = []
            }
            return {
                ...state,
                loading: false,
                items: action.payload,
                page: page,
                per_page: per_page,
                records: [...state.records, ...records],
                total: total,
                totalPage: Math.ceil(total / per_page),
                isAuthenticated: false,
            };
            case PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_SUCCESS:
                if (action.payload.page === 1) {
                    state.questionnaireStudentRecords = []
                }
                return {
                    ...state,
                    loading: false,
                    questionnaireDetailsById:action.payload,
                    page: action.payload.page,
                    per_page: action.payload.per_page,
                    total: action.payload.total,
                    totalPage: Math.ceil(action.payload.total / action.payload.per_page),
                    questionnaireStudentRecords:[...state.questionnaireStudentRecords, ...action.payload.records] 
                 
                };
        case PsychometricActionTypes.PSYCHOMETRIC_DATA_STORAGE:
            return { ...state, loading: true };

        case PsychometricActionTypes.PSYCHOMETRIC_DATA_STORAGE_SUCCESS:
            const { psychometricDataStorage } = action;
            return {
                ...state,
                loading: false,
                psychometricDataStorage: psychometricDataStorage
            };
        case PsychometricActionTypes.ADD_QUESTIONNAIRE_SUCCESS:
        case PsychometricActionTypes.ADD_QUESTIONNAIRE_FAIL:
        case PsychometricActionTypes.EDIT_QUESTIONNAIRE_SUCCESS:
        case PsychometricActionTypes.EDIT_QUESTIONNAIRE_FAIL:
            return {
                ...state,
                loading: false,
                addQuestionnaireMapping: action.payload,
            };
        case PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID_SUCCESS:
            const { getData } = action;
            return {
                ...state,
                loading: false,
                psychometricReport: getData
            };
        case PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER_SUCCESS:
            const { activeClassTeacher } = action;
            return {
                ...state,
                loading: false,
                activeClassTeacher: activeClassTeacher
            };
        case PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                loading: false,
                student_list: action.payload.data,
                class_teacher_data: action.payload.class_teacher_data,
            };
        case PsychometricActionTypes.DELETE_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_FAIL:
            return {
                ...state,
                loading: false,
                student_list: [],
            };

        default:
            return state;
    }
}

export { reducer as PsychometricReducer }