import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';
import { RootState, RootActions } from '../Index';
import { AxiosResponse } from 'axios';
import { baseAPI } from '../../Service';
import { Psychometric, Teacher } from '../../services/Config'
import { PsychometricActionTypes } from './Types'
import { LoadMoreType } from '../../components/type';
import toaster from "toasted-notes";
import { notificationMsg } from '../../services/Constants';
import history from '../../History';

//Implement Thunk middle ware
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootActions>;
interface FetchAge {
    type: PsychometricActionTypes.FETCH_AGE;
}

interface FetchAGESuccess {
    type: PsychometricActionTypes.FETCH_AGE_SUCCESS;
    payload: any;
}

interface FetchAGEFail {
    type: PsychometricActionTypes.FETCH_AGE_FAIL;
}

export const fetchAgeGet = (loadMoreType: LoadMoreType): ThunkResult<void> => async dispatch => {
    handleFetchAge(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(Psychometric.GetPsychometricAgeDropdown, {
            params: loadMoreType,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        handleFetchAgeSuccess(dispatch, response.data);
    } catch (e) {
        handleFetchAgeFail(dispatch);
    }
};

export const handleFetchAge = (dispatch: Dispatch<FetchAge>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_AGE });
};

export const handleFetchAgeSuccess = (
    dispatch: Dispatch<FetchAGESuccess>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_AGE_SUCCESS,
        payload: response,
    });

};

export const handleFetchAgeFail = (dispatch: Dispatch<FetchAGEFail>) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_AGE_FAIL
    });
};

//get Studetn class info
interface FetchQuestionnaireViewAnswers {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE;
}

interface FetchQuestionnaireViewAnswersSuccess {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_SUCCESS;
    payload: any;
}

interface FetchQuestionnaireViewAnswersFail {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_FAIL
}


export const getQuestionnaireByAge = (getData: any): ThunkResult<void> => async dispatch => {
    let postValue: any = {
        academic_year: getData.id
    }
    handleStudentViewAnswers(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.get(`${Psychometric.GetAgeQuestionnaireDetails}${getData.id}/`, {
            params: postValue,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        handleStudentViewAnswersSuccess(dispatch, response.data);
    } catch (e) {
        handleStudentViewAnswersFail(dispatch);
    }
};


export const handleStudentViewAnswers = (dispatch: Dispatch<FetchQuestionnaireViewAnswers>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_QUESTIONNAIRE });
};

export const handleStudentViewAnswersSuccess = (
    dispatch: Dispatch<FetchQuestionnaireViewAnswersSuccess>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_SUCCESS,
        payload: response,
        studentViewAnswers: response
    });

};

const handleStudentViewAnswersFail = (dispatch: Dispatch<FetchQuestionnaireViewAnswersFail>) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_FAIL
    });
};

//Add QUESTIONNAIRE set

interface AddQuestionSet {
    type: PsychometricActionTypes.ADD_QUESTIONNAIRE;
}

interface AddQuestionSetSuccess {
    type: PsychometricActionTypes.ADD_QUESTIONNAIRE_SUCCESS;
    payload: any;
}

interface AddQuestionSetFail {
    type: PsychometricActionTypes.ADD_QUESTIONNAIRE_FAIL;
    payload: any;
}

export const CreateQuestionnaireMapping = (question: any,edit:any = false, unique_id:any = null): ThunkResult<void> => async dispatch => {
    let param: any = {}
    if(edit){
        param = {
            unique_id:unique_id
        }
    }
    handleAddQuestionnaire(dispatch);


    try {
        const response: AxiosResponse<any> = await baseAPI.post(Psychometric.CreateQuestionnaireMapping, question,
            { params:param,
                headers: { "Authorization": localStorage.getItem('token') } });
        if (response.data.status === true) {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleAddQuestionnaireSuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleAddQuestionnaireFail(dispatch, response.data);
        }
    } catch (e) {
        handleAddQuestionnaireFail(dispatch, e);
    }
};

const handleAddQuestionnaire = (dispatch: Dispatch<AddQuestionSet>) => {
    dispatch({ type: PsychometricActionTypes.ADD_QUESTIONNAIRE });
};

const handleAddQuestionnaireSuccess = (
    dispatch: Dispatch<AddQuestionSetSuccess>,
    response: any
) => {
    dispatch({ type: PsychometricActionTypes.ADD_QUESTIONNAIRE_SUCCESS, payload: response });
};

const handleAddQuestionnaireFail = (dispatch: Dispatch<AddQuestionSetFail>, response: any) => {
    dispatch({
        type: PsychometricActionTypes.ADD_QUESTIONNAIRE_FAIL,
        payload: response,
        errors: response.data
    });
};


//Update QUESTIONNAIRE set

interface EditQuestionSet {
    type: PsychometricActionTypes.EDIT_QUESTIONNAIRE;
}

interface EditQuestionSetSuccess {
    type: PsychometricActionTypes.EDIT_QUESTIONNAIRE_SUCCESS;
    payload: any;
}

interface EditQuestionSetFail {
    type: PsychometricActionTypes.EDIT_QUESTIONNAIRE_FAIL;
    payload: any;
}

export const editQuestionnaireMapping = (question: any,edit:any = false, unique_id:any = null): ThunkResult<void> => async dispatch => {
    let param: any = {}
    if(edit){
        param = {
            unique_id:unique_id
        }
    }
    handleEditQuestionnaire(dispatch);


    try {
        const response: AxiosResponse<any> = await baseAPI.put(Psychometric.CreateQuestionnaireMapping, question,
            { params:param,
                headers: { "Authorization": localStorage.getItem('token') } });
            
        if (response.data.status === true) {
            
                toaster.notify(response.data.message, {
                    position: 'top',
                    duration: notificationMsg.duration
                });
            
            handleEditQuestionnaireSuccess(dispatch, response.data);
        } else {
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleEditQuestionnaireFail(dispatch, response.data);
        }
    } catch (e) {
        handleEditQuestionnaireFail(dispatch, e);
    }
};

const handleEditQuestionnaire = (dispatch: Dispatch<EditQuestionSet>) => {
    dispatch({ type: PsychometricActionTypes.EDIT_QUESTIONNAIRE });
};

const handleEditQuestionnaireSuccess = (
    dispatch: Dispatch<EditQuestionSetSuccess>,
    response: any
) => {
    dispatch({ type: PsychometricActionTypes.EDIT_QUESTIONNAIRE_SUCCESS, payload: response });
    //history.push('/question');
};

const handleEditQuestionnaireFail = (dispatch: Dispatch<EditQuestionSetFail>, response: any) => {
    dispatch({
        type: PsychometricActionTypes.EDIT_QUESTIONNAIRE_FAIL,
        payload: response,
        errors: response.data
    });
};

interface DeleteQuestionnaireMapping {
    type: PsychometricActionTypes.DELETE_QUESTIONNAIRE;
}

interface DeleteQuestionnaireMappingSuccess {
    type: PsychometricActionTypes.DELETE_QUESTIONNAIRE_SUCCESS;
    payload: any;
}

interface DeleteQuestionnaireMappingFail {
    type: PsychometricActionTypes.DELETE_QUESTIONNAIRE_FAIL;
}

export const deleteQuestionnaireMapping = (deletedId: any): ThunkResult<void> => async dispatch => {
    const getDeleteValue = { "unique_id": deletedId }
    handleDeleteQuestionnaireMapping(dispatch);
    try {
        const response: AxiosResponse<any> = await baseAPI.patch(Psychometric.CreateQuestionnaireMapping+`?unique_id=${deletedId}`, getDeleteValue,
            { headers: { "Authorization": localStorage.getItem('token') } });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            handleDeleteQuestionnaireMappingSuccess(dispatch, response.data);
        } else {
            toaster.notify(getResponse.message, {
                position: 'top',
                duration: notificationMsg.errorNotificationDuration
            });
            handleDeleteQuestionnaireMappingFail(dispatch);
        }

    } catch (e) {
        handleDeleteQuestionnaireMappingFail(dispatch);
    }
};

const handleDeleteQuestionnaireMapping = (dispatch: Dispatch<DeleteQuestionnaireMapping>) => {
    dispatch({ type: PsychometricActionTypes.DELETE_QUESTIONNAIRE });
};

const handleDeleteQuestionnaireMappingSuccess = (
    dispatch: Dispatch<DeleteQuestionnaireMappingSuccess>,
    response: any
) => {
    dispatch({ type: PsychometricActionTypes.DELETE_QUESTIONNAIRE_SUCCESS, payload: response });
};
const handleDeleteQuestionnaireMappingFail = (dispatch: Dispatch<DeleteQuestionnaireMappingFail>) => {
    dispatch({ type: PsychometricActionTypes.DELETE_QUESTIONNAIRE_FAIL });
};


//FETCH QUESTIONNAIRE

interface fetchQuestionnaire {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING;
}

interface fetchQuestionnaireSuccess {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_SUCCESS;
    payload: any;
}

interface fetchQuestionnaireFail {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_FAIL;
    payload: any;
}

export const fetchQuestionnaireMappingPost = (loadMoreType: any): ThunkResult<void> => async dispatch => {
    handleFetchQuestionnaireMapping(dispatch);

    try {
        const response: AxiosResponse<any> = await baseAPI.get(Psychometric.ViewQuestionnaireMapping, {
            params: loadMoreType,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });

        if (response.data.status === true) {
            // toaster.notify(response.data.message, {
            //     position: 'top',
            //     duration: notificationMsg.duration
            // });
            handleFetchQuestionnaireMappingSuccess(dispatch, response.data);
        } else {
            // toaster.notify(response.data.message, {
            //     position: 'top',
            //     duration: notificationMsg.errorNotificationDuration
            // });
            handleFetchQuestionnaireMappingFail(dispatch, response.data);
        }
    } catch (e) {
        handleFetchQuestionnaireMappingFail(dispatch, e);
    }
};

const handleFetchQuestionnaireMapping = (dispatch: Dispatch<fetchQuestionnaire>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING });
};

const handleFetchQuestionnaireMappingSuccess = (
    dispatch: Dispatch<fetchQuestionnaireSuccess>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_SUCCESS, payload: response,
        records: response.data.records,
        per_page: response.data.per_page,
        page: response.data.page,
        total: response.data.total
    });
    //history.push('/question');
};

const handleFetchQuestionnaireMappingFail = (dispatch: Dispatch<fetchQuestionnaireFail>, response: any) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_FAIL,
        payload: response,
        errors: response.data
    });
};

// FETCH Student edit details

interface FetchQuestionnaireMappingById {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID;
}

interface FetchQuestionnaireMappingByIdSuccessId {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID_SUCCESS;
    payload: any;
}

interface FetchQuestionnaireMappingByIdFailId {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID_FAIL;
}

export const fetchQuestionnaireMappingById = (getData: any): ThunkResult<void> => async dispatch => {
    let postValue: any = {
        academic_year: getData.academic_year,
        unique_id: getData.id
    }
    handleFetchQuestionSetId(dispatch);
    try {

        const response: AxiosResponse<any> = await baseAPI.get(`${Psychometric.GetQuestionnaireMappingById}`, {
            params: getData,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });
        const getResponse = JSON.parse(JSON.stringify(response.data));
        if (getResponse.status === true) {
            handleFetchQuestionSetSuccessId(dispatch, response.data);
        } else {
            handleFetchQuestionSetFailId(dispatch);
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
        
        history.push("/psychometric");
        }

    } catch (e) {
        handleFetchQuestionSetFailId(dispatch);
    }
};

export const handleFetchQuestionSetId = (dispatch: Dispatch<FetchQuestionnaireMappingById>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID });
};

const handleFetchQuestionSetSuccessId = (
    dispatch: Dispatch<FetchQuestionnaireMappingByIdSuccessId>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID_SUCCESS,
        payload: response,
        getData: response.data
    });
};

const handleFetchQuestionSetFailId = (dispatch: Dispatch<FetchQuestionnaireMappingByIdFailId>) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_BY_ID_FAIL
    });
};

//get Studetn class info
interface FetchPsychometricDataStorage {
    type: PsychometricActionTypes.PSYCHOMETRIC_DATA_STORAGE;
}

interface FetchPsychometricDataStorageSuccess {
    type: PsychometricActionTypes.PSYCHOMETRIC_DATA_STORAGE_SUCCESS;
    payload: any;
}


export const setPsychometricDataStorage = (getData?: any): ThunkResult<void> => async dispatch => {
    handlePsychometricDataStorage(dispatch);
    try {
        if (getData) {
            let getDetail: any = getData
            handlePsychometricDataStorageSuccess(dispatch, getDetail);
        }

    } catch (e) {
    }
};


export const handlePsychometricDataStorage = (dispatch: Dispatch<FetchPsychometricDataStorage>) => {
    dispatch({ type: PsychometricActionTypes.PSYCHOMETRIC_DATA_STORAGE });
};

export const handlePsychometricDataStorageSuccess = (
    dispatch: Dispatch<FetchPsychometricDataStorageSuccess>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.PSYCHOMETRIC_DATA_STORAGE_SUCCESS,
        payload: response,
        psychometricDataStorage: response
    });

};

// FETCH Student edit details

interface FetchPsychometricReportById {
    type: PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID;
}

interface FetchPsychometricReportByIdSuccessId {
    type: PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID_SUCCESS;
    payload: any;
}

interface FetchPsychometricReportByIdFailId {
    type: PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID_FAIL;
}

export const fetchPsychometricReportById = (getData: any): ThunkResult<void> => async dispatch => {
    let postValue: any = {
        academic_year: getData.academic_year,
        student_id: getData.student_id,
        unique_id: getData.unique_id
    }
    handleFetchPsychometricReportById(dispatch);
    try {

        const response: AxiosResponse<any> = await baseAPI.get(`${Psychometric.GetStudentPsychometricReport}`, {
            params: postValue,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });

        const getResponse = JSON.parse(JSON.stringify(response.data));
        console.log(getResponse)
        if (getResponse.status === true) {
            handleFetchPsychometricReportByIdSuccessId(dispatch, response.data);
        } else {
            handleeFetchPsychometricReportByIdFailId(dispatch);
            history.push("/psychometric");
        }

    } catch (e) {
        handleeFetchPsychometricReportByIdFailId(dispatch);
    }
};

export const handleFetchPsychometricReportById = (dispatch: Dispatch<FetchPsychometricReportById>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID });
};

const handleFetchPsychometricReportByIdSuccessId = (
    dispatch: Dispatch<FetchPsychometricReportByIdSuccessId>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID_SUCCESS,
        payload: response,
        getData: response.data
    });
};

const handleeFetchPsychometricReportByIdFailId = (dispatch: Dispatch<FetchPsychometricReportByIdFailId>) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_PSYCHOMETRIC_REPORT_BY_ID_FAIL
    });
};

// FETCH Active School Class Teacher

interface FetchActiveClassTeacher {
    type: PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER;
}

interface FetchActiveClassTeacherSuccessId {
    type: PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER_SUCCESS;
    payload: any;
}

interface FetchActiveClassTeacherFailId {
    type: PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER_FAIL;
}

export const fetchActiveClassTeacher = (getData: any): ThunkResult<void> => async dispatch => {
    handleFetchActiveClassTeacher(dispatch);
    try {

        const response: AxiosResponse<any> = await baseAPI.post(Teacher.getActiveClassTeacher, getData,
            { headers: { "Authorization": localStorage.getItem('token') } });

        const getResponse = JSON.parse(JSON.stringify(response.data));
        console.log(getResponse)
        if (getResponse.status === true) {
            handleFetchActiveClassTeacherSuccessId(dispatch, response.data);
        } else {
            handleFetchActiveClassTeacherFailId(dispatch);
        }

    } catch (e) {
        handleFetchActiveClassTeacherFailId(dispatch);
    }
};

export const handleFetchActiveClassTeacher = (dispatch: Dispatch<FetchActiveClassTeacher>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER });
};

const handleFetchActiveClassTeacherSuccessId = (
    dispatch: Dispatch<FetchActiveClassTeacherSuccessId>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER_SUCCESS,
        payload: response,
        activeClassTeacher: response.data
    });
};

const handleFetchActiveClassTeacherFailId = (dispatch: Dispatch<FetchActiveClassTeacherFailId>) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_ACTIVE_CLASS_TEACHER_FAIL
    });
};


// FETCH Student list with psychometric Questionnaire

interface FetchStudentListwithQuestionnaire{
    type: PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE;
}

interface FetchStudentListwithQuestionnaireSuccessId {
    type: PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_SUCCESS;
    payload: any;
}

interface FetchStudentListwithQuestionnaireFailId {
    type: PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_FAIL;
}

export const fetchStudentListwithQuestionnaire = (getData: any): ThunkResult<void> => async dispatch => {
    handleFetchStudentListwithQuestionnaire(dispatch);
    try {

        const response: AxiosResponse<any> = await baseAPI.get(Psychometric.GetStudentList, {
            params: getData,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });

        const getResponse = JSON.parse(JSON.stringify(response.data));
        console.log(getResponse)
        if (getResponse.status === true) {
            handleFetchStudentListwithQuestionnaireSuccessId(dispatch, response);
        } else {
            handleFetchStudentListwithQuestionnaireFailId(dispatch);
        }

    } catch (e) {
        handleFetchStudentListwithQuestionnaireFailId(dispatch);
    }
};

export const handleFetchStudentListwithQuestionnaire = (dispatch: Dispatch<FetchStudentListwithQuestionnaire>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE });
};

const handleFetchStudentListwithQuestionnaireSuccessId = (
    dispatch: Dispatch<FetchStudentListwithQuestionnaireSuccessId>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_SUCCESS,
        payload: response.data,
    });
};

const handleFetchStudentListwithQuestionnaireFailId = (dispatch: Dispatch<FetchStudentListwithQuestionnaireFailId>) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_STUDENT_LIST_WITH_QUESTIONNAIRE_FAIL
    });
};


// FETCH Questionnaire Mapping Students

interface FetchQuestionnaireMappingStudents{
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS;
}

interface FetchQuestionnaireMappingStudentsSuccessId {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_SUCCESS;
    payload: any;
}

interface FetchQuestionnaireMappingStudentsFailId {
    type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_FAIL;
}

export const fetchQuestionnaireMappingStudents = (getData: any): ThunkResult<void> => async dispatch => {
    handleFetchQuestionnaireMapping(dispatch);
    try {

        const response: AxiosResponse<any> = await baseAPI.get(Psychometric.GetQuestionnaireMappingStudents, {
            params: getData,
            headers: {
                "Authorization": localStorage.getItem('token')
            }
        });

        const getResponse = JSON.parse(JSON.stringify(response.data));
        console.log(getResponse)
        if (getResponse.status === true) {
            handleFetchQuestionnaireMappingSuccessId(dispatch, response.data);
        } else {
            handleFetchQuestionnaireMappingFailId(dispatch);
            toaster.notify(response.data.message, {
                position: 'top',
                duration: notificationMsg.duration
            });
            history.push("/psychometric");
        }

    } catch (e) {
        handleFetchQuestionnaireMappingFailId(dispatch);
    }
};

export const handleFetchQuestionnaireMappingStudents = (dispatch: Dispatch<FetchQuestionnaireMappingStudents>) => {
    dispatch({ type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS });
};

const handleFetchQuestionnaireMappingSuccessId = (
    dispatch: Dispatch<FetchQuestionnaireMappingStudentsSuccessId>,
    response: any
) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_SUCCESS,
        payload: response.data,
    });
};

const handleFetchQuestionnaireMappingFailId = (dispatch: Dispatch<FetchQuestionnaireMappingStudentsFailId>) => {
    dispatch({
        type: PsychometricActionTypes.FETCH_QUESTIONNAIRE_MAPPING_STUDENTS_FAIL
    });
};

export type PsychometricAction =
    | FetchAGESuccess
    | FetchAGEFail
    | FetchQuestionnaireViewAnswersSuccess
    | FetchQuestionnaireViewAnswersFail;