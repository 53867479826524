import React from 'react';
import { connect } from 'react-redux';
import { ClassesType, ClassesTypes, ISearchBarState } from '../../store/classes/Type';
import { deletePost, fetchClassesPost, fetchClassesPagination } from '../../store/classes/Actions';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb'
import history from '../../History';
import { UserRoles, formValidationPatten } from '../../services/Constants'
import { LoadMoreType } from '../../components/type';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../components/spinner/SpinnerLoader';
import CommonLoader from '../../components/CommonLoader';
import Switch from '@material-ui/core/Switch';
import {CommonModel} from '../../components/CommonModel';
import DataTable from '../../components/datatable/DataTable';
import {tableHeading} from './Types';
import { stopYearData } from '../../store/profile/Actions'

export interface PostsListProps {
  loading: boolean; 
  getYear?:any;
  getpreDatas: any;
  classesDetails: ClassesTypes;
  classesAllItems: any;
  classesDelete: ClassesType;
  deleteDetails: any;
  checkDelateStatus:any;
  deletePost: (classesDelete: any) => any;
  fetchClassesPost: (loadMoreType: LoadMoreType) => any;
  fetchClassesPagination: (loadMoreType?: LoadMoreType) => any;
  stopYearData:()=>any;
  page: number;
  per_page: number;
  totalPage: number;
  records: any;
  total: number;
  stopYear?:any;
}

class ViewClassesDetails extends React.Component<PostsListProps, ISearchBarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      showDelete: false,
      addShow: false,
      acadamicAdmin: false,
      schoolAdmin: false,
      bothData: false,
      teacher: false,
      parent: false,
      deleteClassesId: [],
      query: '',
      classData: [],
      subjectsTotal: 1,
      hasMore: true,
      prev: 0,
      next: 10,
      acsOrder: true,
      descOrder: false,
      page: 1,
      per_page: 10,
      records: [],
      total: 0,
      totalPage: 0,
      search: '',
      SortOrderData:'',
      OrderNameData:'',
      postData:{},
      sort_by:'desc',
      sort_class_name:'fa fa-sort-amount-asc',
      sort_title_name:'Ascending order',
      getCurrectYear: null
    };
    window.scrollTo(0, 0);
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    this.UpdateYearDate()
    this.checkUserType(); 
  }
  componentWillUpdate(nextProps:any, nextState:any) {
		const {stopYear} = this.props;
		if(stopYear === true){
            this.UpdateYearDate(this.props.getYear)
		}
	}
	componentDidUpdate() {
		const {stopYear} = this.props;
    this.checkStatusProcess();
		if(stopYear === true){
			this.props.stopYearData()
		}
	}
    UpdateYearDate(getYear?:any){
    if(getYear && getYear !== undefined && getYear !== null){
        this.setState({getCurrectYear:getYear})
        this.getClassRecord(getYear)
    }else {
        this.setState({getCurrectYear:this.props.getYear})
        this.getClassRecord(this.props.getYear)
    }
    }
  getClassRecord(getYear?:any){
    const postValue:any = {
      page_no: 1,
      search: this.state.search,
      sort_by: this.state.SortOrderData,
      order_by: this.state.OrderNameData,
      academic_year:getYear
    }
    this.props.fetchClassesPagination(postValue).then((res:any)=>{
      window.scrollTo(0, 0);
      this.setState({hasMore:true, page: 1})
    }); 
  }

checkUserType(getYear?:any) {
    const getClassToken = localStorage.getItem('usertype');
    if (getClassToken === UserRoles.acadamicAdmin) {
      this.setState({ acadamicAdmin: true })
    } else if (getClassToken === UserRoles.schoolAdmin) {
      this.setState({ schoolAdmin: true })
    } else if (getClassToken === UserRoles.teacher) {
      this.setState({ teacher: true })
    } else if (getClassToken === UserRoles.parent) {
      this.setState({ parent: true })
    }
  }

  checkStatusProcess(){
    const selectDataList = this.props.deleteDetails;
    const {getCurrectYear} = this.state;
    if(selectDataList === true){ 
      const postValue:any = {
        page_no: 1,
        search: this.state.search,
        sort_by: this.state.SortOrderData,
        order_by: this.state.OrderNameData,
        academic_year:getCurrectYear
      }    
      this.props.fetchClassesPagination(postValue).then((res:any)=>{
        window.scrollTo(0, 0);
      });     
    }
    
   }

  public handleDelete = () => {
    this.setState({ showDelete: false, page: 1, hasMore: true });
    this.props.deletePost(this.state.deleteClassesId).then((res:any)=>{
     
    });
  }
  public hideClassDelete = () => {
    this.setState({ showDelete: false });
  }
  //this is function use to open model box
  public showClassDelete = (getData: any) => {  
    let titleClassMessage: any;
    let postValue:any
    if (getData.isActive === true) {
      titleClassMessage = 'Activate';
    } else {
      titleClassMessage = 'Deactivate';
    }
    if(getData && titleClassMessage){
      postValue = {
        title:'Please Confirm',
        action_status:titleClassMessage,
        action_name:'Class'
      }
      this.setState({ showDelete: true, deleteClassesId: getData, postData:postValue });
    }
    
  }

  public showFilterData = (getdata: any) => {
    const {getCurrectYear, search} = this.state;
    if (getdata.sort_by === 'desc') {
      this.setState({ acsOrder: false })
      const postValue:any = {
        page_no: 1,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        search:search,
        academic_year:getCurrectYear
      }
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'asc',
        sort_class_name:'fa fa-sort-amount-desc',
        sort_title_name:'Descending order'
      })
      this.props.fetchClassesPagination(postValue);
    } else {
      this.setState({ acsOrder: true })
      const postValue:any = {
        page_no: 1,
        sort_by: getdata.sort_by,
        order_by: getdata.order_by,
        search:search,
        page: 1
      }
      this.setState({
        hasMore: true,
        SortOrderData: getdata.sort_by,
        OrderNameData: getdata.order_by,
        page: 1,
        sort_by:'desc',
        sort_class_name:'fa fa-sort-amount-asc',
        sort_title_name:'Ascending order'
      })
      this.props.fetchClassesPagination(postValue);
    }
  }

  public fetchMoreClassData = () => {
    const {getCurrectYear} = this.state;
    if (this.state.hasMore === true) {
     if (Math.ceil(this.props.total / this.props.per_page) > this.props.page) {   
        const postValue:any = {
          page_no: this.state.page + 1,
          search: this.state.search,
          sort_by: this.state.SortOrderData,
          order_by: this.state.OrderNameData,
          academic_year:getCurrectYear
        }              
        this.props.fetchClassesPagination(postValue);
        this.setState({
          page: this.state.page + 1
        }) 
      }

      if (Math.ceil(this.props.total / this.props.per_page) === this.props.page) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }

  private renderClassesManageView(records: any) {
    const { loading } = this.props;
    const { sort_by, sort_class_name, sort_title_name } = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" }; 
    return (
      <div>
        <InfiniteScroll
          dataLength={records.length}
          next={this.fetchMoreClassData}
          hasMore={this.state.hasMore}
          loader={<h4 style={loadingTextCSS}>Loading...</h4>}
        >
          <table className="table table-striped custom-table table-hover">
            <thead>
              <tr>
                <th>
                <button 
                onClick={() => this.showFilterData({ sort_by: sort_by , order_by: 'grade' })} 
                title={sort_title_name} 
                className="headerBold">
                Grade&nbsp;
                 <i className={sort_class_name} aria-hidden="true"></i>
                </button>
                </th>
                <th>
                  <button 
                  onClick={() => this.showFilterData({ sort_by: sort_by, order_by: 'standard' })} 
                  title={sort_title_name}  className="headerBold">
                  Section&nbsp;
                  <i className={sort_class_name} aria-hidden="true"></i>
                  </button>
                  </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ?
                records.map((data: ClassesType, index: number) => (
                  <tr key={index}>
                    <td >{data['grade'] || '-'}</td>
                    <td >{data['standard'] || '-'}</td>
                    <td>                   
                    {/* {data.is_active ?
                        <Link to={`/edit_class/${data.id}`}>
                          <button className="btn btn-primary btn-xs"
                            title="Edit Class"><i className="fa fa-pencil" aria-hidden="true" ></i></button></Link>   : 
                            <button className="btn btn-disable btn-xs"
                            title="Edit Class"><i className="fa fa-pencil" aria-hidden="true" ></i></button>} */}
                      {data.is_active ?
                        <Switch
                        checked={data.is_active}
                        onClick={() => this.showClassDelete({ id: String(data['id']), isActive: false })}
                        name="checkedSchool"
                        inputProps={{ 'aria-label': 'sucess checkbox' }}
                        title="Deactivate Class"
                      /> :
                      <Switch
                      checked={data.is_active}
                      onClick={() => this.showClassDelete({ id: String(data['id']), isActive: true })}
                      name="checkedSchool"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      title="Activate Class"
                    />}
                    </td>
                  </tr>
                ))
                : <CommonLoader/>}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    )
  }
  

  handleInputClassChange = (e: any) => {
    const { value } = e.target;
    const {getCurrectYear} = this.state;
    const getSearchValue = value;
    var intRegex = formValidationPatten.alphanumericTest;
    if(intRegex.test(getSearchValue) || getSearchValue === ''){
       if (getSearchValue) {
      const postSearchValue:any = {
        page_no: 1,
        search: getSearchValue,
        academic_year:getCurrectYear
      }
      this.props.fetchClassesPagination(postSearchValue);
      this.setState({
        hasMore: true,
        page: 1,
        search: getSearchValue
      })
    } else {
      const postSearchValue:any = {
        page_no: 1,
        search: '',
        academic_year:getCurrectYear
      }      
      this.props.fetchClassesPagination(postSearchValue);
      this.setState({
        hasMore: true,
        page: 1,
        search: ''
      })
    }
    }
  }
  refreshClassTable = () =>{
    const {getCurrectYear} = this.state;
    this.setState({
      search: '',
      SortOrderData:'',
      OrderNameData:'',
    }, () => {
      this.UpdateYearDate(getCurrectYear)
    });  
    
  }
  render() {
    const { loading } = this.props;
    const {showDelete, postData, search} = this.state;
    const loadingTextCSS = { display: loading ? "block" : "none" };
    const getClassToken = localStorage.getItem('token');
    const classTotal:any = this.props.total
    if (!getClassToken) {
      history.push("/");
    }
    return (
      <div>
       <CommonModel
       onState={showDelete}
       currentState={postData}
       onConfirm={this.handleDelete}
       onCancel={this.hideClassDelete}
       />
       <DataTable
       tableRecord={this.props.records}
       headerList={tableHeading}
       />
        <div className="page-wrapper">
          <div className="page-content-wrapper">
            <div className="page-content pt-3">
              <BreadCrumb titleName={['Class']} homeName={['Home']} url={['dashboard']} mainPageTitle={['Class']} />
              <div>
              <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="mr-4">
                      <h4 className="pull-right">Total: {classTotal}</h4>
                    </div>
                    </div>
                    </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-topline-red">                  
                      <div className="card-head" style={{ display: this.state.schoolAdmin ? "block" : "none" }}>                     
                        <header>
                          <Link to={'/add_class'}>
                            <button className="btn btn-pink">Add Class</button>
                          </Link>

                        </header>
                        <div className="tools">
                        <div className="d-flex">
										<div>
										        <input
                              placeholder="Search"
                              name="search"
                              value={this.state.search}
                              className="form-control"
                              onChange={this.handleInputClassChange}                           
                            />
										</div>
										<div className="ml-2 mt-2">
											<span className="fa fa-repeat btn-color box-refresh pointer" onClick={this.refreshClassTable}></span>
											<span className="t-collapse btn-color fa fa-chevron-down pointer" ></span>
										</div>
									</div>
                            
                        </div>
                      </div>
                      <div className="card-body no-padding height-9">
                        <div className="row">
                          <div className="table-responsive">
                            {this.renderClassesManageView(this.props.records)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={loadingTextCSS}><SpinnerLoader /></div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => { 
  return {
    getYear:state.profile.getYear,
		stopYear:state.profile.yearStatusFails,
    total: state.classes.total,
    per_page: state.classes.per_page,
    records: state.classes.records,
    page: state.classes.page,
    totalPage: state.classes.totalPage,
    loading:state.classes.loading,
    deleteDetails:state.classes.isAuthenticated,
    checkDelateStatus:state.classes.recordDelete
  };
};
export default connect(
  mapStateToProps, { fetchClassesPost, deletePost, fetchClassesPagination, stopYearData }
)(ViewClassesDetails);
