export interface QuestionBankState {
    loading: boolean;
    errors: any;
    records: any;
    items: any,
    page: number,
    per_page: number,
    total: number,
    getExamTypeEdit: any;
    isAuthenticated: boolean | null;
    addExamType: any;
    getInternalGradle: any;
    getInternalSubject: any;
    getExternalSubject: any;
    getLanguage: any,
    getMappingEdit: any;
    addSubjectMapping: any,
    reloadQuestions: any,
}

export enum QuestionBankTypes {
    EXAM_TYPE = 'EXAM_TYPE',
    EXAM_TYPE_SUCCESS = 'EXAM_TYPE_SUCCESS',
    EXAM_TYPE_FAIL = 'EXAM_TYPE_FAIL',
    ADD_EXAM_TYPE = 'ADD_EXAM_TYPE',
    ADD_EXAM_TYPE_SUCCESS = 'ADD_EXAM_TYPE_SUCCESS',
    ADD_EXAM_TYPE_FAIL = 'ADD_EXAM_TYPE_FAIL',
    FETCH_EXAM_TYPE_BY_ID = 'FETCH_EXAM_TYPE_BY_ID',
    FETCH_EXAM_TYPE_BY_ID_SUCCESS = 'FETCH_EXAM_TYPE_BY_ID_SUCCESS',
    FETCH_EXAM_TYPE_BY_ID_FAIL = 'FETCH_EXAM_TYPE_BY_ID_FAIL',
    EDIT_EXAM_TYPE = 'EDIT_EXAM_TYPE',
    EDIT_EXAM_TYPE_SUCCESS = 'EDIT_EXAM_TYPE_SUCCESS',
    EDIT_EXAM_TYPE_FAIL = 'EDIT_EXAM_TYPE_FAIL',
    DELETE_EXAM_TYPE = 'DELETE_FEEDBACK_QUESTIONS',
    DELETE_EXAM_TYPE_SUCCESS = 'DELETE_EXAM_TYPE_SUCCESS',
    DELETE_EXAM_TYPE_FAIL = 'DELETE_EXAM_TYPE_FAIL',
    BLUE_PRINT = 'BLUE_PRINT',
    BLUE_PRINT_SUCCESS = 'BLUE_PRINT_SUCCESS',
    BLUE_PRINT_FAIL = 'BLUE_PRINT_FAIL',
    ADD_BLUE_PRINT = 'ADD_BLUE_PRINT',
    ADD_BLUE_PRINT_SUCCESS = 'ADD_BLUE_PRINT_SUCCESS',
    ADD_BLUE_PRINT_FAIL = 'ADD_BLUE_PRINT_FAIL',
    EDIT_BLUE_PRINT = 'EDIT_BLUE_PRINT',
    EDIT_BLUE_PRINT_SUCCESS = 'EDIT_BLUE_PRINT_SUCCESS',
    EDIT_BLUE_PRINT_FAIL = 'EDIT_BLUE_PRINT_FAIL',
    FETCH_BLUE_PRINT_BY_ID = 'FETCH_BLUE_PRINT_BY_ID',
    FETCH_BLUE_PRINT_BY_ID_SUCCESS = 'FETCH_BLUE_PRINT_BY_ID_SUCCESS',
    FETCH_BLUE_PRINT_BY_ID_FAIL = 'FETCH_BLUE_PRINT_BY_ID_FAIL',
    DELETE_BLUE_PRINT_BY_ID = 'DELETE_BLUE_PRINT_BY_ID',
    DELETE_BLUE_PRINT_BY_ID_SUCCESS = 'DELETE_BLUE_PRINT_BY_ID_SUCCESS',
    DELETE_BLUE_PRINT_BY_ID_FAIL = 'DELETE_BLUE_PRINT_BY_ID_FAIL',
    BLUE_PRINT_GRADE_DROPDOWN = 'BLUE_PRINT_GRADE_DROPDOWN',
    BLUE_PRINT_GRADE_DROPDOWN_SUCCESS = 'BLUE_PRINT_GRADE_DROPDOWN_SUCCESS',
    BLUE_PRINT_GRADE_DROPDOWN_FAIL = 'BLUE_PRINT_GRADE_DROPDOWN_FAIL',
    BLUE_PRINT_SUBJECT_DROPDOWN = 'BLUE_PRINT_SUBJECT_DROPDOWN',
    BLUE_PRINT_SUBJECT_DROPDOWN_SUCCESS = 'BLUE_PRINT_SUBJECT_DROPDOWN_SUCCESS',
    BLUE_PRINT_SUBJECT_DROPDOWN_FAIL = 'BLUE_PRINT_SUBJECT_DROPDOWN_FAIL',
    BLUE_PRINT_EXAM_TYPE_DROPDOWN = 'BLUE_PRINT_EXAM_TYPE_DROPDOWN',
    BLUE_PRINT_EXAM_TYPE_DROPDOWN_SUCCESS = 'BLUE_PRINT_EXAM_TYPE_DROPDOWN_SUCCESS',
    BLUE_PRINT_EXAM_TYPE_DROPDOWN_FAIL = 'BLUE_PRINT_EXAM_TYPE_DROPDOWN_FAIL',
    BLUE_PRINT_QUESTION_TYPE_DROPDOWN = 'BLUE_PRINT_QUESTION_TYPE_DROPDOWN',
    BLUE_PRINT_QUESTION_TYPE_DROPDOWN_SUCCESS = 'BLUE_PRINT_QUESTION_TYPE_DROPDOWN_SUCCESS',
    BLUE_PRINT_QUESTION_TYPE_DROPDOWN_FAIL = 'BLUE_PRINT_QUESTION_TYPE_DROPDOWN_FAIL',
    BLUE_PRINT_LESSON_DROPDOWN = 'BLUE_PRINT_LESSON_DROPDOWN',
    BLUE_PRINT_LESSON_DROPDOWN_SUCCESS = 'BLUE_PRINT_LESSON_DROPDOWN_SUCCESS',
    BLUE_PRINT_LESSON_DROPDOWN_FAIL = 'BLUE_PRINT_LESSON_DROPDOWN_FAIL',
    BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN = 'BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN',
    BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_SUCCESS = 'BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_SUCCESS',
    BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_FAIL = 'BLUE_PRINT_DIFFICULTY_LEVEL_DROPDOWN_FAIL',
    INTERNAL_GRADLE = 'INTERNAL_GRADLE',
    INTERNAL_GRADLE_SUCCESS = 'INTERNAL_GRADLE_SUCCESS',
    INTERNAL_GRADLE_FAIL = 'INTERNAL_GRADLE_DROPDOWN_FAIL',
    INTERNAL_SUBJECT = 'INTERNAL_SUBJECT',
    INTERNAL_SUBJECT_SUCCESS = 'INTERNAL_SUBJECT_SUCCESS',
    INTERNAL_SUBJECT_FAIL = 'INTERNAL_SUBJECT_FAIL',
    EXTERNAL_SUBJECT = 'EXTERNAL_SUBJECT',
    EXTERNAL_SUBJECT_SUCCESS = 'EXTERNAL_SUBJECT_SUCCESS',
    EXTERNAL_SUBJECT_FAIL = 'EXTERNAL_SUBJECT_FAIL',
    LANGUAGE_DROPDOWN = 'LANGUAGE_DROPDOWN',
    LANGUAGE_DROPDOWN_SUCCESS = 'LANGUAGE_DROPDOWN_SUCCESS',
    LANGUAGE_DROPDOWN_FAIL = 'LANGUAGE_DROPDOWN_FAIL',
    FETCH_SUBJECT_MAPPING_BY_ID = 'FETCH_SUBJECT_MAPPING_BY_ID',
    FETCH_SUBJECT_MAPPING_BY_ID_SUCCESS = 'FETCH_SUBJECT_MAPPING_BY_ID_SUCCESS',
    FETCH_SUBJECT_MAPPING_BY_ID_FAIL = 'FETCH_SUBJECT_MAPPING_BY_ID_FAIL',
    ADD_SUBJECT_MAPPING = 'ADD_SUBJECT_MAPPING',
    ADD_SUBJECT_MAPPING_SUCCESS = 'ADD_SUBJECT_MAPPING_SUCCESS',
    ADD_SUBJECT_MAPPING_FAIL = 'ADD_SUBJECT_MAPPING_FAIL',
    EDIT_SUBJECT_MAPPING = 'EDIT_SUBJECT_MAPPING',
    EDIT_SUBJECT_MAPPING_SUCCESS = 'EDIT_SUBJECT_MAPPING_SUCCESS',
    EDIT_SUBJECT_MAPPING_FAIL = 'EDIT_SUBJECT_MAPPING_FAIL',
    DELETE_SUBJECT_MAPPING = 'DELETE_SUBJECT_MAPPING',
    DELETE_SUBJECT_MAPPING_SUCCESS = 'DELETE_SUBJECT_MAPPING_SUCCESS',
    DELETE_SUBJECT_MAPPING_FAIL = 'DELETE_SUBJECT_MAPPING_FAIL',
    FETCH_SUBJECT_MAPPING = 'FETCH_SUBJECT_MAPPING',
    FETCH_SUBJECT_MAPPING_SUCCESS = 'FETCH_SUBJECT_MAPPING_SUCCESS',
    FETCH_SUBJECT_MAPPING_FAIL = 'FETCH_SUBJECT_MAPPING_FAIL',
    FETCH_GENERATE_QUESTIONS = 'FETCH_GENERATE_QUESTIONS',
    FETCH_GENERATE_QUESTIONS_SUCCESS = 'FETCH_GENERATE_QUESTIONS_SUCCESS',
    FETCH_GENERATE_QUESTIONS_FAIL = 'FETCH_GENERATE_QUESTIONS_FAIL',
    FETCH_RELOAD_QUESTIONS = 'FETCH_RELOAD_QUESTIONS',
    FETCH_RELOAD_QUESTIONS_SUCCESS = 'FETCH_RELOAD_QUESTIONS_SUCCESS',
    FETCH_RELOAD_QUESTIONS_FAIL = 'FETCH_RELOAD_QUESTIONS_FAIL',
    FETCH_BLUE_PRINT_DROPDOWN = 'FETCH_BLUE_PRINT_DROPDOWN',
    FETCH_BLUE_PRINT_DROPDOWN_SUCCESS = 'FETCH_BLUE_PRINT_DROPDOWN_SUCCESS',
    FETCH_BLUE_PRINT_DROPDOWN_FAIL = 'FETCH_BLUE_PRINT_DROPDOWN_FAIL',
    FETCH_QUESTION_PAPER_DROPDOWN = 'FETCH_QUESTION_PAPER_DROPDOWN',
    FETCH_QUESTION_PAPER_DROPDOWN_SUCCESS = 'FETCH_QUESTION_PAPER_DROPDOWN_SUCCESS',
    FETCH_QUESTION_PAPER_DROPDOWN_FAIL = 'FETCH_QUESTION_PAPER_DROPDOWN_FAIL',
    FETCH_QUESTION_PAPER = 'FETCH_QUESTION_PAPER',
    FETCH_QUESTION_PAPER_SUCCESS = 'FETCH_QUESTION_PAPER_SUCCESS',
    FETCH_QUESTION_PAPER_FAIL = 'FETCH_QUESTION_PAPER_FAIL',
    CREATE_QUESTION_PAPER = 'CREATE_QUESTION_PAPER',
    CREATE_QUESTION_PAPER_SUCCESS = 'CREATE_QUESTION_PAPER_SUCCESS',
    CREATE_QUESTION_PAPER_FAIL = 'CREATE_QUESTION_PAPER_FAIL',
    DOWNLOAD_QUESTION_PAPER = 'DOWNLOAD_QUESTION_PAPER',
    DOWNLOAD_QUESTION_PAPER_SUCCESS = 'DOWNLOAD_QUESTION_PAPER_SUCCESS',
    DOWNLOAD_QUESTION_PAPER_FAIL = 'DOWNLOAD_QUESTION_PAPER_FAIL',
    DELETE_QUESTION_PAPER = 'DELETE_QUESTION_PAPER',
    DELETE_QUESTION_PAPER_SUCCESS = 'DELETE_QUESTION_PAPER_SUCCESS',
    DELETE_QUESTION_PAPER_FAIL = 'DELETE_QUESTION_PAPER_FAIL',
    FETCH_FEEDBACK_QUESTIONS = 'FETCH_FEEDBACK_QUESTIONS',
    FETCH_FEEDBACK_QUESTIONS_SUCCESS = 'FETCH_FEEDBACK_QUESTIONS_SUCCESS',
    FETCH_FEEDBACK_QUESTIONS_FAIL = 'FETCH_FEEDBACK_QUESTIONS_FAIL',
    FETCH_FEEDBACK_QUESTIONS_BY_ID = 'FETCH_FEEDBACK_QUESTIONS_BY_ID',
    FETCH_FEEDBACK_QUESTIONS_BY_ID_SUCCESS = 'FETCH_FEEDBACK_QUESTIONS_BY_ID_SUCCESS',
    FETCH_FEEDBACK_QUESTIONS_BY_ID_FAIL = 'FETCH_FEEDBACK_QUESTIONS_BY_ID_FAIL',
    ADD_FEEDBACK_QUESTIONS = 'ADD_FEEDBACK_QUESTIONS',
    ADD_FEEDBACK_QUESTIONS_SUCCESS = 'ADD_FEEDBACK_QUESTIONS_SUCCESS',
    ADD_FEEDBACK_QUESTIONS_FAIL = 'ADD_FEEDBACK_QUESTIONS_FAIL',
    EDIT_FEEDBACK_QUESTIONS = 'EDIT_FEEDBACK_QUESTIONS',
    EDIT_FEEDBACK_QUESTIONS_SUCCESS = 'EDIT_FEEDBACK_QUESTIONS_SUCCESS',
    EDIT_FEEDBACK_QUESTIONS_FAIL = 'EDIT_FEEDBACK_QUESTIONS_FAIL',
    DELETE_FEEDBACK_QUESTIONS = 'DELETE_FEEDBACK_QUESTIONS',
    DELETE_FEEDBACK_QUESTIONS_SUCCESS = 'DELETE_FEEDBACK_QUESTIONS_SUCCESS',
    DELETE_FEEDBACK_QUESTIONS_FAIL = 'DELETE_FEEDBACK_QUESTIONS_FAIL',
}